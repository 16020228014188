/* eslint-disable no-undef */
import axios from 'axios'
import store from '@/store'
import _this from '@/services/analytics'
import Helpers from '@/services/helpers'
import EventBus from '@/helpers/eventBus'
import TikTokAnalytics from '@/services/analytics/tiktok'
class Analytics {
  GoogleGlobalTag = {
    _store: {},
    _setup: async function (id) {
      if (this._store.ready) {
        return true
      }
      if (id) {
        await Helpers._loadJS(`https://www.googletagmanager.com/gtag/js?id=${id}`)
        // console.log(`[ANALYTICS] GoogleGlobalTag OK | id ${id}`)
        let storeDomain = store.getters.CheckoutConfig.shopify_api.domain

        window.dataLayer = window.dataLayer || []
        window.gtag = function () { window.dataLayer.push(arguments) }
        var gtag = function () { window.dataLayer.push(arguments) }

        gtag()
        this._store.gtag = gtag

        gtag('set', 'linker', { 'domains': [storeDomain] })
        gtag('js', new Date())

        this._store.ready = true
        return true
      } else {
        return new Error('GTAG ID não encontrado')
      }
    }
  }
  GoogleAnalytics = {
    _store: {},
    _setup: async function (id) {
      let EVOLVE_ANALYTICS_ID = id || store.getters.CheckoutConfig.analyticsConfig.google_analytics
      if (this._store.ready) {
        return true
      }
      if (EVOLVE_ANALYTICS_ID) {
        await _this.GoogleGlobalTag._setup(EVOLVE_ANALYTICS_ID)

        await _this.GoogleGlobalTag._store.gtag('config', `${EVOLVE_ANALYTICS_ID}`)
        // console.log(`[ANALYTICS] GoogleAnalytics OK | id ${EVOLVE_ANALYTICS_ID}`)

        this._store.id = EVOLVE_ANALYTICS_ID
        this._store.ready = true
        return true
      } else {
        return new Error('Analytics ID não encontrado')
      }
    },
    error: async (description = `not set`, fatal = false) => {
      await this.GoogleAnalytics._setup()
      _this.GoogleGlobalTag._store.gtag('event', 'exception', {
        'description': description,
        'fatal': fatal
      })
    },
    performance: {
      start: (id) => {
        if (id) {
          Helpers._timing.start(id)
        } else {
          return new Error(`Perfomance tracking doesn't have ID`)
        }
      },
      send: async (id, description = 'JS Request') => {
        if (id) {
          Helpers._timing.end(id)
          let performanceMetrics = Helpers._timing.get(id)
          await this.GoogleAnalytics._setup()
          _this.GoogleGlobalTag._store.gtag('event', 'timing_complete', {
            'name': id,
            'value': performanceMetrics.end - performanceMetrics.start,
            'event_category': `${description}`
          })
        } else {
          return new Error(`Perfomance tracking doesn't have ID`)
        }
      }
    },
    event: async (category = `not set`, action = `not set`, label = `not set`, value = 1) => {
      await this.GoogleAnalytics._setup()
      _this.GoogleGlobalTag._store.gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
      })
      // console.log(`[ANALYTICS] [EVENTO] GA EVENTO DISPARADO | category: ${category} | action: ${action} | label: ${label} | value: ${value}`)
    },
    ecommerce_event: async () => {
      await this.GoogleAnalytics._setup()
      let Checkout = store.getters.Checkout
      if (Checkout) {
        let CheckoutProducts = Checkout.products.map((product, index) => {
          return {
            list_position: index,
            name: `${product.title} - ${product.variant_title}`,
            variant: `${product.variant_title}`,
            category: product.type,
            quantity: product.quantity,
            id: `${product.sku}`,
            price: product.original_price
          }
        })

        if (Checkout.activeStep === 1) {
          _this.GoogleGlobalTag._store.gtag('event', 'begin_checkout', {
            coupon: Checkout.discount_codes ? Checkout.discount_codes.map(code => code.code).join(' - ') : '',
            currency: 'BRL',
            value: Checkout.totals.total,
            items: CheckoutProducts
          })
        }

        _this.GoogleGlobalTag._store.gtag('event', 'checkout_progress', {
          coupon: Checkout.discount_codes ? Checkout.discount_codes.map(code => code.code).join(' - ') : '',
          currency: 'BRL',
          checkout_option: Checkout.payment_info.type || '',
          checkout_step: Checkout.activeStep,
          value: Checkout.totals.total,
          items: CheckoutProducts
        })
      }
      // console.log(`[ANALYTICS] [EVENTO] GA EVENTO DISPARADO | category: ${category} | action: ${action} | label: ${label} | value: ${value}`)
    },
    pageview: async (title, page) => {
      await this.GoogleAnalytics._setup()
      // _this.GoogleGlobalTag._store.gtag('config', `${this.GoogleAnalytics._store.id}`, {
      //   'page_title': title,
      //   'page_path': page
      // });

      _this.GoogleGlobalTag._store.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_title: title,
        page_path: page
      })
      // console.log(`[ANALYTICS] [PAGEVIEW] GA PAGEVIEW DISPARADO | page_title: ${page} | page_path: ${page} `)
    },
    conversion: async (conversion) => {
      let eventDetails = conversion.order
      let $eventDetailsItems = eventDetails.products.map((product, index) => {
        let prod = {
          id: product.product_id,
          title: product.title,
          quantity: product.quantity,
          variant: {
            id: product.variant_id,
            sku: product.sku,
            title: product.variant_title !== 'Default Title' ? product.variant_title : null,
            price: product.original_price
          },
          category: product.type
        }
        return {
          list_position: index,
          name: `${prod.title}${prod.variant.title ? ` - ${prod.variant.title}` : ``}`,
          variant: prod.variant.title,
          product_title: `${prod.title}`,
          product_id: `${prod.id}`,
          variant_title: prod.variant.title,
          category: prod.category,
          variant_id: `${prod.variant.id}`,
          quantity: prod.quantity,
          id: `${prod.variant.sku}`,
          sku: `${prod.variant.sku}`,
          price: prod.variant.price
        }
      })

      let $eventDetailsDiscountCode = eventDetails.discount_codes.find(c => {
        return c.code
      })

      // let products = eventDetails.products.map((product, index) => {
      //   return {
      //     list_position: index,
      //     name: `${product.title} - ${product.variant_title}`,
      //     variant: `${product.variant_title}`,
      //     quantity: product.quantity,
      //     id: `${product.sku}`,
      //     price: product.original_price
      //   }
      // })
      await this.GoogleAnalytics._setup()
      _this.GoogleGlobalTag._store.gtag('event', `purchase`, {
        transaction_id: `#${eventDetails.order.number}`,
        coupon: $eventDetailsDiscountCode ? $eventDetailsDiscountCode.code : null,
        value: eventDetails.totals.total,
        shipping: eventDetails.totals.shipping,
        tax: eventDetails.totals.addition,
        currency: 'BRL',
        items: $eventDetailsItems
      })
      // console.log(`[ANALYTICS] [PAGEVIEW] GA PAGEVIEW DISPARADO | page_title: ${page} | page_path: ${page} `)
    }
  }
  GoogleAdwords = {
    _store: {},
    _setup: async function (id) {
      this._store.adwords_id = store.getters.CheckoutConfig.analyticsConfig.google_adwords
      this._store.adwords_conversion_id = store.getters.CheckoutConfig.analyticsConfig.google_conversion_id

      if (this._store.ready) {
        return true
      }
      if (this._store.adwords_id) {
        await _this.GoogleGlobalTag._setup(`${this._store.adwords_id}`)

        _this.GoogleGlobalTag._store.gtag('config', `${this._store.adwords_id}`)
        this._store.id = `${this._store.adwords_id}`
        this._store.ready = true

        return true
      } else {
        return new Error('Adwords ID não encontrado')
      }
    },
    pageview: async (checkout, pageType = 'cart') => {
      await this.GoogleAdwords._setup()

      _this.GoogleGlobalTag._store.gtag('event', 'page_view', {
        'send_to': `${this.GoogleAdwords._store.adwords_send_to}`,
        'value': +`${checkout.totals.total}`,
        'currency': 'BRL',
        'transaction_id': ``,
        'ecomm_pagetype': `${pageType}`,
        'ecomm_prodid': checkout.products.map(p => p.product_id),
        'ecomm_totalvalue': +`${checkout.totals.total}`
      })
    },
    conversion: async (conversion) => {
      await this.GoogleAdwords._setup()
      let adwordsSendTo = _this.GoogleAdwords._store.adwords_id

      if (_this.GoogleAdwords._store.adwords_conversion_id) {
        adwordsSendTo = `${_this.GoogleAdwords._store.adwords_id}/${_this.GoogleAdwords._store.adwords_conversion_id}`
      }

      _this.GoogleGlobalTag._store.gtag('event', 'conversion', {
        'send_to': `${adwordsSendTo}`,
        'value': `${conversion.order.totals.total}`,
        'currency': 'BRL',
        'transaction_id': `${conversion.order.order.number}`
      })
    }
  }
  GoogleTagManager = {
    _store: {},
    _setup: async function (id) {
      let EVOLVE_GTM_ID = id || store.getters.CheckoutConfig.analyticsConfig.google_tag_manager
      if (this._store.ready) {
        return true
      }
      if (id) {
        await Helpers._loadJS(`https://www.googletagmanager.com/gtm.js?id=${EVOLVE_GTM_ID}`, () => {}, 'head')

        // console.log(`[ANALYTICS] GoogleTagManager OK | id ${EVOLVE_GTM_ID}`)
        window.dataLayer = window.dataLayer || []
        var gtm = function () { window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }) }
        gtm()

        this._store.gtm = gtm
        this._store.ready = true

        return true
      } else {
        return new Error('GoogleTagManager ID não encontrado')
      }
    }
  }
  FacebookGlobalTag = {
    _store: {},
    _setup: async function (id) {
      if (this._store.ready) {
        return true
      }
      if (id) {
        let dataLayer
        dataLayer = window.fbq = function () {
          dataLayer.callMethod ? dataLayer.callMethod.apply(dataLayer, arguments) : dataLayer.queue.push(arguments)
        }
        if (!window._fbq) window._fbq = dataLayer
        dataLayer.push = dataLayer
        dataLayer.loaded = !0
        dataLayer.version = '2.0'
        dataLayer.queue = []

        await Helpers._loadJS(`https://connect.facebook.net/en_US/fbevents.js`, () => {})
        // console.log(`[ANALYTICS] FacebookGlobalTag OK | id ${id}`)

        this._store.fbq = window.fbq
        this._store.ready = true
        return true
      } else {
        return new Error('FacebookGlobalTag ID não encontrado')
      }
    }
  }
  FacebookPixel = {
    _store: {},
    _setup: async function (id) {
      let EVOLVE_FBPIXEL_ID = id || store.getters.CheckoutConfig.analyticsConfig.facebook_pixel
      if (this._store.ready) {
        return true
      }
      if (EVOLVE_FBPIXEL_ID) {
        await _this.FacebookGlobalTag._setup(EVOLVE_FBPIXEL_ID)

        _this.FacebookGlobalTag._store.fbq('init', EVOLVE_FBPIXEL_ID)
        _this.FacebookGlobalTag._store.fbq('track', 'PageView')
        // console.log(`[ANALYTICS] FacebookPixel OK | id ${EVOLVE_FBPIXEL_ID}`)
        this._store.id = EVOLVE_FBPIXEL_ID
        this._store.ready = true

        return true
      } else {
        return reject(new Error('FacebookPixel ID não encontrado'))
      }
    },
    conversion: (conversion) => {
      // console.info('Starting Facebook Conversion Tracking');
      let CheckoutID = store.getters.Checkout.id
      let EventID = `${CheckoutID}:Purchase`
      this.FacebookPixel._setup().then(() => {
        let products = conversion.order.products.map((product) => {
          return {
            id: product.variant_id,
            quantity: product.quantity
          }
        })
        if (conversion.order.totals.total === 0 || conversion.order.totals.total >= 90000) {
          axios.post(
            'https://prod-24.centralus.logic.azure.com:443/workflows/a28cdff6c61f46f296c34a2f8e52c7d3/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SB2MnzCnUg2aMp4Nxogqh6VQPPYdKB5WHS2eMxmPz5o',
            {
              req_timestamp: new Date(Date.now()).toISOString(),
              req_origin: window.location.href,
              req_company: store?.getters?.CheckoutConfig?.company?.name,
              req_checkout_id: CheckoutID,
              req_customer_email: conversion?.order?.customer?.email,
              req_conversion_value: `${conversion?.order?.totals?.total}`,
              req_body: JSON.stringify(conversion?.order)
            }
          )
          return true
        }
        fbq('track', 'Purchase', {
          value: conversion.order.totals.total,
          currency: 'BRL',
          contents: products,
          content_type: 'product'
        }, { eventID: EventID })
        // console.log(`[ANALYTICS] [CONVERSION] FBPIXEL CONVERSION DISPARADO`)
      })
    },
    initiateCheckout: (checkout) => {
      try {
        // console.info('Starting Facebook InitiateCheckout Tracking');
        let CheckoutID = checkout.id
        let EventID = `${CheckoutID}:InitiateCheckout`
        this.FacebookPixel._setup().then(() => {
          let products = checkout.products.map((product) => {
            return {
              id: product.variant_id,
              quantity: product.quantity
            }
          })
          fbq('track', 'InitiateCheckout', {
            value: checkout.totals.total,
            currency: 'BRL',
            contents: products,
            content_type: 'product'
          }, { eventID: EventID })
        })
      } catch (error) {
        throw new Error(error.message)
      }
    }
  }
  GlobalTracking = {
    _store: {},
    _setup: function (id) {
      return new Promise((resolve, reject) => {
        window.dataLayer = window.dataLayer || []
        if (window.dataLayer) {
          return resolve()
        } else {
          return reject(new Error('GlobalTracking not setup'))
        }
      })
    },
    event: (eventName, eventDetails) => {
      let $eventDetailsItems = eventDetails.products.map((product, index) => {
        let prod = {
          id: product.product_id,
          title: product.title,
          quantity: product.quantity,
          variant: {
            id: product.variant_id,
            sku: product.sku,
            title: product.variant_title !== 'Default Title' ? product.variant_title : null,
            price: product.original_price
          },
          category: product.type
        }
        return {
          list_position: index,
          name: `${prod.title}${prod.variant.title ? ` - ${prod.variant.title}` : ``}`,
          variant: prod.variant.title,
          product_title: `${prod.title}`,
          product_id: `${prod.id}`,
          variant_title: prod.variant.title,
          category: prod.category,
          variant_id: `${prod.variant.id}`,
          quantity: prod.quantity,
          id: `${prod.variant.sku}`,
          sku: `${prod.variant.sku}`,
          price: prod.variant.price
        }
      })

      let $eventDetailsDiscountCode = eventDetails.discount_codes.find(c => {
        return c.code
      })

      let $event = {
        'event': `evolvehub.${eventName}`,
        'data': {
          'evolvehub_id': eventDetails.id,
          'transaction_id': eventDetails.order.number ? `${eventDetails.order.number}` : null,
          'order_id': eventDetails.order.id ? +`${eventDetails.order.id}` : null,
          'step': eventDetails.activeStep,
          'discount_code': $eventDetailsDiscountCode ? $eventDetailsDiscountCode.code : null,
          'value': eventDetails.totals.total,
          'shipping': eventDetails.totals.shipping,
          'tax': 0,
          'currency': 'BRL',
          'items': $eventDetailsItems
        }
      }
      return window.dataLayer.push($event)
    },
    step: (checkout) => {
      this.GlobalTracking._setup().then(() => {
        return this.GlobalTracking.event('step', checkout)
      })
    },
    conversion: (conversion) => {
      this.GlobalTracking._setup().then(() => {
        return this.GlobalTracking.event('purchase', conversion.order)
      })
    }
  }
  TikTokPixel = new TikTokAnalytics();
  _services = {
    setInitiateEvents: async () => {
      let analyticsConfig = store.getters.CheckoutConfig.analyticsConfig
      let Checkout = store.getters.Checkout
      // this.GlobalTracking.initiateCheckout(Checkout)
      // if (analyticsConfig.google_analytics) { this.Googlethis.initiateCheckout(Checkout) }
      // if (analyticsConfig.google_adwords && getConversionID('google_adwords')) { this.GoogleAdwords.initiateCheckout(Checkout) }
      if (analyticsConfig.facebook_pixel) { this.FacebookPixel.initiateCheckout(Checkout) }
    },
    setPageviewEvents: async (pageDetails) => {
      let analyticsConfig = store.getters.CheckoutConfig.analyticsConfig
      let Checkout = store.getters.Checkout
      if (analyticsConfig.google_adwords) {
        await this.GoogleAdwords.pageview(Checkout)
      }
      if (analyticsConfig.tiktok_pixel) {
        await this.TikTokPixel.pageview(`${pageDetails.to.meta.title}`, `${pageDetails.to.fullPath}`)
      }
    },
    setPurchaseEvents: async (pageDetails) => {
      let analyticsConfig = store.getters.CheckoutConfig.analyticsConfig
      let Checkout = store.getters.Checkout
      if (analyticsConfig.google_adwords) {
        await this.GoogleAdwords.pageview(Checkout, 'purchase')
      }
      if (analyticsConfig.tiktok_pixel) {
        await this.TikTokPixel.pageview(`${pageDetails.to.meta.title}`, `${pageDetails.to.fullPath}`)
      }
    }
  }
  _init = () => {
    EventBus.$on('checkout-created', async () => {
      setTimeout(async () => {
        await this._services.setInitiateEvents()
      }, 1000)
    })
    EventBus.$on('checkout-progress', async (e) => {
      let details = e
      setTimeout(async () => {
        await this._services.setPageviewEvents(details)
      }, 250)
    })
    EventBus.$on('checkout-closed', async (e) => {
      let details = e
      setTimeout(async () => {
        await this._services.setPurchaseEvents(details)
      }, 250)
    })
  }
}
export default new Analytics()
