<template>
  <div class="Checkout__Step ShippingMethods" ref="CheckoutStep">
    <el-form ref="form" class="Checkout__Form--Custom Form--Custom">
      <div class="Checkout__Section">
        <ContactInfoPreview :edit="true" :show-products="false" :data="Checkout"  :fields="['contact_info', 'shipping_info']" />
      </div>
      <div class="Checkout__Section">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="el-form-outer">
              <h2 class="Checkout__Section__Title">Entrega</h2>
              <div v-loading="CheckoutStatus.ShippingMethods.get === 'LOADING'">
                <el-alert
                  v-if="ShippingMethods && ShippingMethods.length === 0 && CheckoutStatus.ShippingMethods.get === 'IDLE'"
                  title="NÃO ENCONTRAMOS NENHUM METODO DE ENVIO"
                  type="warning"
                  show-icon
                >
                  Seu endereço não retornou nenhum método de envio. Por favor volte para o   <a  href="#" type="warning" @click="goToPrevStep(Checkout)">passo anterior</a> e altere seu endereço
                </el-alert>
                <el-alert
                  v-if="!ShippingMethods && CheckoutStatus.ShippingMethods.get === 'ERROR'"
                  title="ERRO AO BUSCAR MÉTODOS DE ENVIO"
                  type="error"
                  show-icon
                >
                  Ocorreu um erro ao buscar seus métodos de envio.
                  <br />
                  <br />
                  <a href="#">Atualize essa página</a> ou
                  <el-button type="danger" size="mini">Fale com nosso suporte</el-button>
                </el-alert>
                <ShippingMethodsPicker/>
                <!-- <p @click="ChooseShippingMethod('bug')">BBUG</p> -->
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="ShippingMethods && AppConfig.shipping_information && AppConfig.shipping_information.additional_services && AppConfig.shipping_information.additional_services.services.length">
            <div class="el-form-outer">
              <h2 class="Checkout__Section__Title">{{AppConfig.shipping_information.additional_services.title}}</h2>
              <div>
                <div v-for="(service, index) in AppConfig.shipping_information.additional_services.services" :key="index">
                  <ProductUpsellCheckbox v-if="service.type === 'product'" :title="service.title" :subtitle="service.subtitle" :variant_id="service.variant_id" />
                </div>
              </div>

            </div>
          </el-col>
          <el-col :span="24" v-if="CheckoutStatus.ShippingMethods.update === 'NOT_SELECTED'">
            <el-alert
              title="Selecione um tipo de entrega"
              type="error"
              description="É necessário selecionar um tipo de entrega para continuar"
              show-icon
            ></el-alert>
            <br>
            <br>
          </el-col>
          <el-col :span="12" :offset="6">
            <el-button
              type="primary"
              class="el-button--block Checkout__Button--primary"
              :class="{'Checkout__Button--active': !$v.$invalid && !CheckoutStatus.ShippingMethods.get}"
              :disabled="$v.$invalid || CheckoutStatus.ShippingMethods.get === 'LOADING'"
              @click="nextStep(Checkout)"
              :loading="CheckoutStatus.ShippingMethods.update === 'LOADING'"
            >
              Continuar
              <i class="el-icon-arrow-right"></i>
            </el-button>
            <el-button type="text"  class="el-button--block" @click="goToPrevStep(Checkout)">
              <i class="el-icon-arrow-left"></i> Voltar
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ShippingMethodsPicker from '@/components/_shippingMethods/shippingMethodPicker/ShippingMethodsDefault.vue'
import ContactInfoPreview from '@/components/contactInfo/ContactInfoPreview.vue'
import ProductUpsellCheckbox from '@/components/_general/ProductUpsellCheckbox.vue'
import { mapFilters } from '@/helpers/filters'
// import Animation from '@/services/animation'

export default {
  name: 'ShippingMethods',
  components: {
    ContactInfoPreview,
    ProductUpsellCheckbox,
    ShippingMethodsPicker
  },
  data () {
    return {
      chosenMethod: '',
      shipping: ''
    }
  },
  validations: {
    chosenMethod: { required }
  },
  mounted () {
    // const { CheckoutStep } = this.$refs
    // const timeline = Animation.timeline
    // timeline
    //   .from(CheckoutStep.querySelectorAll('.Checkout__Section'), { duration: 1, y: -5, opacity: 1, stagger: 0.1, ease: 'expo.inOut' })
  },
  beforeMount () {
    let _EvolveHubDiscountCode = localStorage.getItem('discount_code')
    if (!_EvolveHubDiscountCode) {
      this.GetShippingMethods(this.Checkout.id)
    }
  },
  computed: {
    ...mapGetters([
      'Checkout',
      'AppConfig',
      'ShippingMethods',
      'ActiveShippingMethod',
      'CheckoutStatus'
    ])
  },
  methods: {
    ...mapActions([
      'setCheckoutStatus',
      'goToPrevStep',
      'SetShippingMethods',
      'GetShippingMethods',
      'SetCheckoutShippingMethod',
      'updateShippingMethods'
    ]),
    ...mapFilters(['formatMoney']),
    nextStep (Checkout) {
      Checkout.activeStep = 2
      if (this.ActiveShippingMethod.handle) {
        this.updateShippingMethods({
          checkoutId: this.$route.params.checkout_id,
          handle: this.ActiveShippingMethod.handle
        })
      } else {
        this.setCheckoutStatus({
          ShippingMethods: {
            update: 'NOT_SELECTED'
          }
        })
      }
    },
    ChooseShippingMethod (methodHandle) {
      let ShippingMethodOBJ =
        this.ShippingMethods.filter(method => {
          return method.handle === methodHandle
        })[0] ||
        this.ShippingMethods[0] ||
        null
      if (ShippingMethodOBJ) {
        this.setCheckoutStatus({
          ShippingMethods: {
            update: 'IDLE'
          }
        })
        this.chosenMethod = ShippingMethodOBJ.handle
        this.SetCheckoutShippingMethod(ShippingMethodOBJ)
      }
    }
  },
  watch: {
    ShippingMethods (methods) {
      if (methods) {
        let method = methods && methods.length ? methods[0].handle : null
        this.ChooseShippingMethod(method)
      }
      // this.ChooseShippingMethod(this.Checkout.chosen_delivery_option.handle)
    },
    deep: true
  }
}
</script>
