import eventBus from '../../helpers/eventBus'
import { PaymentServices } from '../PaymentServices/paymentServices'
import { MoipServices } from '../PaymentServices/moipServices'
import { MercadoPagoServices } from '../PaymentServices/mercadoPagoServices'
import { PagBrasilServices } from '../PaymentServices/pagBrasilServices'
import { PagarMeServices } from '../PaymentServices/pagarMeServices'
import { ZoopServices } from '../PaymentServices/zoopServices'
import { SafraPayServices } from '../PaymentServices/safraPayServices'
import { MaxiPagoServices } from '../PaymentServices/maxiPagoServices'
import { IOPayServices } from '../PaymentServices/ioPayServices'

class PaymentFactory {
  constructor (gatewayName, fallbackGatewayName) {
    this._paymentServices = {}
  }
  getPaymentServices (gatewayName, fallbackGatewayName) {
    try {
      if (gatewayName) {
        this._paymentServices.default = this[`_${gatewayName.toLowerCase()}`]()
        if (fallbackGatewayName) {
          this._paymentServices.fallback = this[`_${fallbackGatewayName.toLowerCase()}`]()
        }
        return this._paymentServices
      }
    } catch {
      return PaymentServices()
    }
    return PaymentServices()
  }

  async setPubKeys (defaultGatewayPubKey, fallbackGatewayPubKey) {
    try {
      if (!defaultGatewayPubKey) {
        throw new Error('Missing infos to generate public keys')
      }
      let _pubkeyDefault, _pubkeyFallback

      _pubkeyDefault = await this._paymentServices.default.setPubKey(defaultGatewayPubKey)

      if (this._paymentServices.fallback && fallbackGatewayPubKey) {
        _pubkeyFallback = await this._paymentServices.fallback.setPubKey(fallbackGatewayPubKey)
      }

      return {
        default: _pubkeyDefault,
        fallback: _pubkeyFallback
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async getPaymentHashes (number, cvc, expirationMonth, expirationYear, holderFullName, holderDocument) {
    try {
      if (!number || !cvc || !expirationMonth || !expirationYear || !holderFullName || !holderDocument) {
        throw new Error('Missing infos to generate payment hash')
      }
      let _hashDefault, _hashFallback

      _hashDefault = await this.#getDefaultHash(holderFullName, holderDocument, number, cvc, expirationMonth, expirationYear)
      if (this._paymentServices.fallback) {
        _hashFallback = await this.#getHashFallback(holderFullName, holderDocument, number, cvc, expirationMonth, expirationYear)

        if (!_hashDefault.successfullyCreated && !_hashFallback.successfullyCreated) {
          throw new Error(_hashDefault.error)
        }
      } else {
        if (!_hashDefault.successfullyCreated) {
          throw new Error(_hashDefault.error)
        }
      }
      return {
        default: _hashDefault,
        fallback: _hashFallback
      }
    } catch (error) {
      eventBus.$emit('creditCardHashFailed', error.message)
      throw new Error(error.message)
    }
  }

  async #getDefaultHash (holderFullName, holderDocument, number, cvc, expirationMonth, expirationYear) {
    try {
      var hashDefault = ''
      this._paymentServices.default.setUserName(holderFullName)
      this._paymentServices.default.setDocumentNumber(holderDocument)
      hashDefault = await this._paymentServices.default.createPaymentHash(number, cvc, expirationMonth, expirationYear)
      if (!hashDefault || hashDefault === '') {
        return {
          successfullyCreated: false,
          error: '',
          hash: ''
        }
      }
      return {
        successfullyCreated: true,
        hash: hashDefault
      }
    } catch (error) {
      return {
        successfullyCreated: false,
        error: error.message,
        hash: ''
      }
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  async #getHashFallback (holderFullName, holderDocument, number, cvc, expirationMonth, expirationYear) {
    try {
      var hashFallback = ''
      this._paymentServices.fallback.setUserName(holderFullName)
      this._paymentServices.fallback.setDocumentNumber(holderDocument)
      hashFallback = await this._paymentServices.fallback.createPaymentHash(number, cvc, expirationMonth, expirationYear)

      if (!hashFallback || hashFallback === '') {
        return {
          successfullyCreated: false,
          error: ''
        }
      }

      return {
        successfullyCreated: true,
        hash: hashFallback
      }
    } catch (error) {
      return {
        successfullyCreated: false,
        error: error.message
      }
    }
  }

  _wirecard () {
    return new MoipServices()
  }

  _mercadopago () {
    return new MercadoPagoServices()
  }

  _pagbrasil () {
    return new PagBrasilServices()
  }

  _pagarme () {
    return new PagarMeServices()
  }

  _zoop () {
    return new ZoopServices()
  }

  _safrapay () {
    return new SafraPayServices()
  }

  _maxipago () {
    return new MaxiPagoServices()
  }

  _iopay () {
    return new IOPayServices()
  }
}

export default new PaymentFactory()
