import Vue from 'vue'
import VueRouter from 'vue-router'
import API from '../api'

import store from '@/store'
import EventBus from '@/helpers/eventBus'
import RouterService from '@/services/routerService'
import Tracking from '@/services/tracking'
import Analytics from '@/services/analytics'

import Arrival from '@/views/Checkout/Arrival.vue'
import Recover from '@/views/Checkout/Recover.vue'
import DraftOrder from '@/views/Checkout/DraftOrder.vue'
import Checkout from '@/views/Checkout/Content.vue'

import Order from '@/views/Checkout/Order.vue'
import OrderProcessing from '@/views/Checkout/OrderProcessing.vue'
import OrderSuccess from '@/views/Checkout/OrderSuccess.vue'
import OrderPending from '@/views/Checkout/OrderPending.vue'
import OrderCancelled from '@/views/Checkout/OrderCancelled.vue'

import ContactInformation from '@/views/Checkout/_steps/ContactInformation.vue'
import ShippingMethods from '@/views/Checkout/_steps/ShippingMethods.vue'
import PaymentOptions from '@/views/Checkout/_steps/Payments/PaymentOptions.vue'

Vue.use(VueRouter)

const AppConfig = store.getters.AppConfig

const ifAuthenticated = (to, from, next) => {
  const _store = store
  if (to.fullPath.indexOf('/Checkout') > -1) {
    if (_store.getters.Checkout || to.params.checkout_id) {
      next()
      return
    } else {
      next('/Arrival')
      return
    }
  }
  next()
}
const isOrderProcessing = () => {
  const _store = store
  if (_store.getters.Checkout && _store.getters.CheckoutStatus.Order.close === 'LOADING') {
    return true
  }
  return false
}
const ifOrderProcessing = (to, from, next) => {
  isOrderProcessing ? next() : next(false)
}
const checkActiveStep = (to, next) => {
  const _store = store
  if (_store.state.Checkout.Checkout) {
    let toUrlSplitted = to.fullPath.split('/')
    let targetStepNumber = RouterService._steps.filter(step => {
      return step.url.indexOf(toUrlSplitted[toUrlSplitted.indexOf(step.url)]) > -1
    })[0].stepNumber
    let checkoutStepNumber = _store.state.Checkout.Checkout.activeStep
    next(!(targetStepNumber > checkoutStepNumber))
    _store.dispatch('SetCheckoutStep', targetStepNumber > checkoutStepNumber ? checkoutStepNumber : targetStepNumber)
  } else {
    next({
      path: '/Checkout/' + to.params.checkout_id,
      query: {
        discount: to.query.discount
      }
    })
  }
}

const changePageTitle = (to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    .forEach(tag => document.head.appendChild(tag))
}

const routes = [
  {
    path: '/Arrival/:cart_token?',
    name: 'Arrival',
    props: true,
    component: Arrival,
    meta: {
      title: `${AppConfig.name} - Checkout - Início`,
      metaTags: [
        {
          name: 'description',
          content: `${AppConfig.name} - Checkout - Início`
        },
        {
          property: 'og:description',
          content: `${AppConfig.name} - Checkout - Início`
        }
      ]
    }
  },
  {
    path: '/Recover/',
    name: 'Recover',
    props: true,
    component: Recover,
    meta: {
      title: `${AppConfig.name} - Recuperar Carrinho`,
      metaTags: [
        {
          name: 'description',
          content: `${AppConfig.name} - Recuperar Carrinho`
        },
        {
          property: 'og:description',
          content: `${AppConfig.name} - Recuperar Carrinho`
        }
      ]
    }
  },
  {
    path: '/DraftOrder/:draft_order_id?',
    name: 'DraftOrder',
    props: true,
    component: DraftOrder,
    meta: {
      title: `${AppConfig.name} - Fatura`,
      metaTags: [
        {
          name: 'description',
          content: `${AppConfig.name} - Fatura`
        },
        {
          property: 'og:description',
          content: `${AppConfig.name} - Fatura`
        }
      ]
    }
  },
  {
    path: '/Order/:checkout_id?',
    name: 'Order',
    props: true,
    component: Order,
    children: [
      {
        path: 'success',
        name: 'OrderSuccess',
        props: true,
        component: OrderSuccess,
        meta: {
          title: `${AppConfig.name} - Pedido realizado com sucesso`,
          metaTags: [
            {
              name: 'description',
              content: `${AppConfig.name} - Pedido realizado com sucesso`
            },
            {
              property: 'og:description',
              content: `${AppConfig.name} - Pedido realizado com sucesso`
            }
          ]
        }
      },
      {
        path: 'pending',
        name: 'OrderPending',
        props: true,
        component: OrderPending,
        meta: {
          title: `${AppConfig.name} - Pedido pendente`,
          metaTags: [
            {
              name: 'description',
              content: `${AppConfig.name} - Pedido pendente`
            },
            {
              property: 'og:description',
              content: `${AppConfig.name} - Pedido pendente`
            }
          ]
        }
      },
      {
        path: 'cancelled',
        name: 'OrderCancelled',
        props: true,
        component: OrderCancelled,
        meta: {
          title: `${AppConfig.name} - Pedido cancelado`,
          metaTags: [
            {
              name: 'description',
              content: `${AppConfig.name} - Pedido cancelado`
            },
            {
              property: 'og:description',
              content: `${AppConfig.name} - Pedido cancelado`
            }
          ]
        }
      }
    ]
  },
  {
    path: '/Checkout/:checkout_id?',
    name: 'Checkout',
    props: true,
    component: Checkout,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'contact_information',
        name: 'ContactInformation',
        props: true,
        component: ContactInformation,
        meta: {
          title: `${AppConfig.name} - Informações de cliente`,
          metaTags: [
            {
              name: 'description',
              content: `${AppConfig.name} - Informações de cliente`
            },
            {
              property: 'og:description',
              content: `${AppConfig.name} - Informações de cliente`
            }
          ]
        }
      },
      {
        path: 'shipping_methods',
        name: 'ShippingMethods',
        props: true,
        component: ShippingMethods,
        meta: {
          title: `${AppConfig.name} - Métodos de envio`,
          metaTags: [
            {
              name: 'description',
              content: `${AppConfig.name} - Métodos de envio`
            },
            {
              property: 'og:description',
              content: `${AppConfig.name} - Métodos de envio`
            }
          ]
        }
      },
      {
        path: 'payment_methods',
        name: 'PaymentOptions',
        props: true,
        component: PaymentOptions,
        meta: {
          title: `${AppConfig.name} - Métodos de pagamento`,
          metaTags: [
            {
              name: 'description',
              content: `${AppConfig.name} - Métodos de pagamento`
            },
            {
              property: 'og:description',
              content: `${AppConfig.name} - Métodos de pagamento`
            }
          ]
        }
      },
      {
        path: 'processing',
        name: 'OrderProcessing',
        props: true,
        component: OrderProcessing,
        beforeEnter: ifOrderProcessing,
        meta: {
          title: `${AppConfig.name} - Processando pedido`,
          metaTags: [
            {
              name: 'description',
              content: `${AppConfig.name} - Processando pedido`
            },
            {
              property: 'og:description',
              content: `${AppConfig.name} - Processando pedido`
            }
          ]
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  changePageTitle(to, from, next)
  if (to.name === 'Checkout') {
    ifAuthenticated(to, from, next)
  } else if ((to.name === 'ContactInformation' || to.name === 'ShippingMethods' || to.name === 'PaymentOptions')) {
    checkActiveStep(to, next)
  } else {
    next(true)
  }
})
router.afterEach(async (to, from) => {
  const _store = store
  const Checkout = _store?.state?.Checkout?.Checkout

  if (Checkout) {
    sendViewContent(Checkout.id)
    let userIdentified = !!(Checkout && Checkout.customer.email)
    let userPropsOBJ = {
      $first_name: userIdentified ? Checkout.customer.first_name : null,
      $last_name: userIdentified ? Checkout.customer.last_name : null,
      $email: userIdentified ? Checkout.customer.email : null,
      $created: new Date().toISOString()
    }
    let trackingOBJ = {
      company: _store.state.CheckoutConfig.CheckoutConfig ? _store.state.CheckoutConfig.CheckoutConfig.company.name : null,
      checkout_id: Checkout ? Checkout.id : null,
      type: 'event',
      page: `${to.name}`
    }

    let setUtmParams = (to, from, Checkout) => {
      let _origin = from
      let utmParams = Object.keys(_origin.query).filter(q => q.includes('utm'))
      if (utmParams.length) {
        for (let utm of utmParams) {
          _store.dispatch('SetCustomAttribute', [utm, _origin.query[utm]])
        }
        _store.dispatch('SendCustomAttributes')
      }
    }
    let setCartAttributes = async (to, from, Checkout) => {
      let _origin = from
      let CartAttributes = Object.keys(_origin.query).filter(q => q.includes('note_attributes'))
      if (CartAttributes.length) {
        for (let CartAttribute of CartAttributes) {
          await _store.dispatch('SetCustomAttribute', [CartAttribute.replace(/[[\]']+|note_attributes/g, ''), _origin.query[CartAttribute]])
        }
        await _store.dispatch('SendCustomAttributes')
      }
    }

    Analytics.GlobalTracking.step(Checkout)
    Tracking.event(`Page ${to.name}`, trackingOBJ)
    Tracking.setUserProps(userPropsOBJ)
    if (!Checkout.closed) {
      await setCartAttributes(to, from, Checkout)
      setUtmParams(to, from, Checkout)
    }
  }

  if (store.getters.CheckoutConfig && store.getters.CheckoutConfig.analyticsConfig.google_analytics) {
    await Analytics.GoogleAnalytics.ecommerce_event()
    await Analytics.GoogleAnalytics.event(`Checkout`, `Behavior`, `Enter: ${to.name}`)
    await Analytics.GoogleAnalytics.pageview(`${to.meta.title}`, `${to.fullPath}`)

    if (!['OrderProcessing', 'Order', 'OrderSuccess', 'OrderPending', 'OrderCancelled'].includes(to.name)) {
      if (from.name !== 'Arrival') {
        EventBus.$emit('checkout-progress', {
          checkout: Checkout,
          from: from,
          to: to
        })
      }
    } else {
      if (
        (AppConfig.analytics.purchase_trigger_page === 'thank_you' && to.name === 'Order') ||
        (AppConfig.analytics.purchase_trigger_page === 'order_processing' && to.name === 'OrderProcessing')
      ) {
        EventBus.$emit('checkout-closed', {
          checkout: Checkout,
          from: from,
          to: to
        })
      }
    }
  }
})

async function sendViewContent (checkoutId) {
  await API.post(`checkouts/${checkoutId}/AddViewContent`, {
    eventSourceUrl: window.location.href.toString()
  })
}
export default router
